import $ajax from './utils/ajax-adapter';

export default (function () {

	const ENDPOINT = '/tasktags';

	return {
		namespaced: true,
		state: {
			taskTags: []
		},
		mutations: {
			setFetchedTags(state, taskTags) {
				state.taskTags = taskTags;
			},
			updateFetchedTags(state, taskTag){
				state.taskTags.push(taskTag);
			},
			resetState(state) {
				state.taskTags = [];
			}
		},
		getters: {
			
		},
		actions: {
			async getAll({ commit }) {
				// if (state.taskTags.length) {
				// 	return state.taskTags;
				// }
				let tags = await $ajax.get(ENDPOINT);
				commit('setFetchedTags', tags.data);
				return tags.data;
			},

			async createTaskTag({ commit }, tasktag){
					let output = await $ajax.post(ENDPOINT, {}, tasktag);
					commit('updateFetchedTags', output.data);
					return output.data;
			},
			clearCache({ commit }) {
				commit('resetState');
			}
		}
	};
})();