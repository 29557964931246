export default {
	'av.global.search' : 'Search',
	'av.global.notifications': 'notifications',
	'av.global.home': 'Home',
	'av.global.tasks': 'Tasks',
	'av.global.projects': 'Projects',
	'av.global.create': 'Create',
	'av.global.logout': 'Logout',
	'av.global.cancel': 'Cancel',
	'av.global.attachments.placeholder': 'Drop files here or click here to browse',
	'av.tasks.add' : 'Add Task',
	'av.tasks.sub.add': 'Add Subtask',
	'av.tasks.inlineadd.tips': 'Tip: Press ‘enter’ to add task, ‘cmd + enter’ to add and open task',
	'av.tasks.inlineadd.placeholder.name' : 'Enter task name',
	'av.tasks.details': 'Task Details',
	'av.subTasks.details': 'SubTask Details',
	'av.tasks.details.activity': 'Activity',
	'av.tasks.details.tag': 'Task tags',
	'av.tasks.details.attachments': 'Attachments',
	'av.tasks.details.checklist': 'Checklist',
	'av.tasks.details.subtasks': 'Subtasks',
	'av.tasks.details.comments': 'Comments',
	'av.tasks.details.assignedto': 'Assigned To',
	'av.tasks.details.owners': 'Owners',
	'av.tasks.details.followers': 'Followers',
	'av.tasks.details.data.taskid' : 'Task #',
	'av.tasks.details.data.startdate': 'Start Date',
	'av.tasks.details.data.createdon': 'Created On',
	'av.tasks.details.data.duedate': 'Due Date',
	'av.tasks.view.list' : 'List View',
	'av.tasks.view.board': 'Board View',
	'av.tasks.view.kanban.sortby' : 'Sort By',
	'av.tasks.view.kanban.groupby': 'Group By',
	'av.tasks.inlineaddsubtask.placeholder.name':'Add Sub Task',
	'av.tasks.inlineaddcomment.placeholder.name':'Add a comment',
	'av.taskscreation.checklistadd.placeholder.name':'Add a checklist and press Enter'
};