import $ajax from './utils/ajax-adapter';

export default (function () {
	
	const ENDPOINT_ORG = '/organizations';
    const ENDPOINT_CURRENT_ORG = '/current_organization';
	const ENDPOINT_ROLES = '/roles';
	const ENDPOINT_UPDATEROLE = '/update-member-role';

	return {
		namespaced: true,
		state: {
			allOrganization : [],
			currentOrg : [],
			roles : []
		},
		mutations: {
			setAllOrganizations(state, organization) {
				state.allOrganization = organization;
			},
			// getOneOrg(state, organization){
			// 	Object.assign(state.tasks.find(t => t.taskId === task.taskId), task);
			// },
			setCurrentOrganization(state, currentorg){
				state.currentOrg = currentorg;
			},
			updateCurrentOrganization(state, updatedorg){
				if(state.currentOrg.createdUser.userId === updatedorg.createdUser.userId){
					state.currentOrg = updatedorg;
				}
			},
			updateUsers(state, updateUserMembers){
				state.currentOrg.members = updateUserMembers;
			},
			setFetchedRoles(state, roles){
				state.roles = roles;
			},
			updateMemberRole(state, updateMember){
				state.currentOrg.members.forEach((memb) => {
					if(memb.orgUserId === updateMember.orgUserId){
						memb = updateMember;
					}
					
				});
			}
		},
		getters: {

		},
		actions: {
			async getAllOrg({ commit }) {
				let org = await $ajax.get(`${ENDPOINT_ORG}`);
				commit('setAllOrganizations', org.data);
				return org.data;
			},

			async getOneOrg(context, orgId){
				await $ajax.get(`${ENDPOINT_ORG}/${orgId}`);
				// commit('getOneOrg', oneOrg.data);
				// return oneOrg.data;
			},

			async getCurrentOrg({ commit }) {
				let org = await $ajax.get(`${ENDPOINT_ORG}${ENDPOINT_CURRENT_ORG}`);
				commit('setCurrentOrganization', org.data);
				return org.data;
			},

			async createOrg({ commit }, newOrg){
				let createdOrg = await $ajax.post(`${ENDPOINT_ORG}`, {}, newOrg, {} );
				commit('setCurrentOrganization', createdOrg.data);
				return createdOrg.data;
			},

			async updateOrg({ commit }, updateOrg){
				let updatedOrg = await $ajax.put(`${ENDPOINT_ORG}/${updateOrg.orgId}`, {}, updateOrg, {} );
				commit('updateCurrentOrganization', updatedOrg.data);
				return updatedOrg.data;
			},

			async getRoles({commit}){
				let roles = await $ajax.get(`${ENDPOINT_ROLES}`, {} );
				commit('setFetchedRoles', roles.data);
				return roles.data;
			},

			async updateMemberRole({commit}, memb){
				let updateMembRole = await $ajax.put(`${ENDPOINT_UPDATEROLE}/${memb.orgUserId}`, {},  memb.roles[0]);
				commit('updateMemberRole', updateMembRole.data);
				return updateMembRole.data;
			},

			async inviteUsers({ commit }, inviteUsers){
				let OrgId = inviteUsers.orgId;
				delete inviteUsers.orgId;
				let updateUser = await $ajax.post(`${ENDPOINT_ORG}/${OrgId}/invite`, {}, inviteUsers );
				commit('updateUsers', updateUser.data);
				return updateUser;
			},

			async deleteOrgMember(context, orgUserId){
				await $ajax.delete(`/remove-member/${orgUserId}`, {} );
			}


		}
	};
})();