import $ajax from './utils/ajax-adapter';
import moment from 'moment';

export default (function () {

	const ENDPOINT = '/events';

	const _changeEventprop = function (event) {

		event.id = event.eventId;
		event.title = event.eventName;
		event.start = event.startDate;
		event.start = moment(event.start, ["DD-MM-YYYY hh:mm A"]).format("YYYY-MM-DD" + "T" + "HH:mm");
		event.end = event.endDate;
		event.end = moment(event.end, ["DD-MM-YYYY hh:mm A"]).format("YYYY-MM-DD" + "T" + "HH:mm");
		delete event.eventId;
		delete event.eventName;
		delete event.startDate;
		delete event.endDate;

		return event;
		
	};

	return {
		namespaced: true,
		state: {
			events: []
		},
		mutations: {
			setFetchedEvents(state, events) {
				state.events = events;
			},
            pushNewEvent(state, event) {
				state.events.push(event);
			},
			updateEvent(state, event) {
				Object.assign(state.events.find(t => t.id === event.id), event);
			}
		},
		getters: {
			
		},
		actions: {
			async getAllEvents({ commit }) {
				let events = await $ajax.get(ENDPOINT);
				let output = events.data;
				await output.forEach((event) => {
					_changeEventprop(event);
				});
				commit('setFetchedEvents', output);
				return output;
			},
            
            async createEvent( { commit }, event){
                let events = await $ajax.post('/event', {}, event);
				let output = await _changeEventprop(events.data);
                commit('pushNewEvent', output);
				return output;

            },

			async updateEvent( { commit }, eventObj){
				let events = await $ajax.put(`/event/${eventObj.eventId}`, {}, eventObj, {});
				let output = await _changeEventprop(events.data);
                commit('updateEvent', output);
				return output;

			},

			deleteEvent(context, eventObj){
				$ajax.delete(`/event/${eventObj.eventId}`, {});
			}
		}
	};
})();