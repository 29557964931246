import { createApp } from 'vue';
import App from './av-app.vue';
import router from './router';
import store from './store';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import mitt from 'mitt';
import vSelect from 'vue-select';
import { createI18n } from 'vue-i18n';
import localeEn from './locales/en';

import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
// import 'element-plus/dist/index.css';
// import 'element-plus/es/components/message/style/css';

import { DateTime } from 'luxon';

const i18n = createI18n({
	locale : 'en',
	fallbackLocale : 'en',
	messages : {
		en: localeEn
	}
});

const app = createApp(App);
const eventBus = mitt();

app.use(router);
app.use(store);
app.use(i18n);
app.use(ElementPlus);
app.use('Quill', Quill);
app.component('v-select', vSelect);
app.config.globalProperties.$lxnFormatDate = (value) => {
	return value ? DateTime.fromMillis(value).toLocaleString(DateTime.DATE_MED) : '';
};

app.config.globalProperties.eventBus = eventBus;

app.mount('#av-app');

window.$$avQS = document.querySelector.bind(document);
window.$$avQSAll = document.querySelectorAll.bind(document);
Element.prototype.$$avQS = function (selector) {
	return this.querySelector(selector);
};
Element.prototype.$$avQSAll = function (selector) {
	return this.querySelectorAll(selector);
};
