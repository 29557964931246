import $ajax from './utils/ajax-adapter';

export default (function () {

	const ENDPOINT = '/taskhistories';

	return {
		namespaced: true,
		state: {
			taskHistories: []
		},
		mutations: {
            setFetchedHistory( state, history ){
                state.taskHistories = history;
			}
		},
		getters: {
			
		},
		actions: {
			async getAllHistory({ commit }) {
				let history = await $ajax.get(`${ENDPOINT}/1`);
				commit('setFetchedHistory', history.data);
                // console.log(history.data);
				return history.data;
			},
			clearCache({ commit }) {
				commit('resetState');
			}
		}
	};
})();