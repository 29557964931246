import $ajax from './utils/ajax-adapter';

export default (function () {

	const ENDPOINT = '/taskstatuses';

	return {
		namespaced: true,
		state: {
			statuses: []
		},
		mutations: {
			setFetchedStatuses(state, statuses) {
				statuses.forEach(status => (status.color = status.color || '#8EB65F'));
				state.statuses = statuses;
			},
			resetState(state) {
				state.statuses = [];
			}
		},
		getters: {
			
		},
		actions: {
			async getAll({ commit, state }) {
				if (state.statuses.length) {
					return state.statuses;
				}
				let statuses = await $ajax.get(ENDPOINT);
				// console.log(statuses.data);
				commit('setFetchedStatuses', statuses.data);
				return statuses.data;
			},
			clearCache({ commit }) {
				commit('resetState');
			}
		}
	};
})();