import { createStore } from 'vuex';
import tasks from './tasks';
import taskstatuses from './taskstatuses';
import users from './users';
import taskpriorities from './taskpriorities';
import tasktags from './tasktags';
import organizations from './organizations';
import events from './event';
import taskHistory from './taskHistory';

export default createStore({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		tasks,
		taskstatuses,
		users,
		taskpriorities,
		tasktags,
		organizations,
		events,
		taskHistory
	}
});
