import $ajax from './utils/ajax-adapter';
import moment from 'moment-timezone';

export default (function () {

	const ENDPOINT = '/users';
	const CURRENTUSERENDPOINT = '/current_user';
	const USERENDPOINT = '/users';
    const USERPREENDPOINT = 'user-preferences';

	const _populateUserTestProps = function (userData) {
		userData.dateFormat = userData.dateFormat || "MM/DD/YYYY";
		userData.timeZone = userData.timeZone || moment.tz.guess(true);
		return userData;
	};

	const _populateUserpreTestProps = function (userPre) {
		userPre.userPreferences.taskPreference.mode = userPre.userPreferences.taskPreference.mode || 'list';
		userPre.userPreferences.taskPreference.taskCategory = userPre.userPreferences.taskPreference.taskCategory || 'All Tasks';
		return userPre;
	};

	return {
		namespaced: true,
		state: {
			users: [],
			currentAuthUser : [],
			preferenceForUser: []
		},
		mutations: {

			setFetchedUsers(state, users) {
				state.users = users;
			},
			setFetchedCurrentUsers(state, currentAuthUsers) {
				state.currentAuthUser = currentAuthUsers;
			},
			updateCurrentUsers(state, updateUser){
				if(state.currentAuthUser.userId === updateUser.userId){
					state.currentAuthUser = updateUser;
				}
			},
			setFetchedPreference(state, userPre) {
				state.preferenceForUser = userPre;
			},
			// updateFetchedPreference(state, userPre){
			// 	Object.assign(state.preferenceForUser.find(e => e.preferenceId === userPre.preferenceId), userPre);
			// },
			resetState(state){
				state.users = [];
			}
		},
		getters: {
			
		},
		actions: {
			async getAll({ commit, state }) {
				if(state.users.length){
					return state.users;
				}
				let users = await $ajax.get(ENDPOINT);
				commit('setFetchedUsers', users.data);
				return users.data;
			},

			async getCurrentUser({ commit, state }){
				
				if(Object.entries(state.currentAuthUser).length){
					return state.currentAuthUser;
				}
				let currentUsers = await $ajax.get(`${ENDPOINT}${CURRENTUSERENDPOINT}`);
				_populateUserTestProps(currentUsers.data);
				commit('setFetchedCurrentUsers', currentUsers.data);
				return currentUsers.data;

			},

			async updatecurrentUser({ commit }, userData){
				let updateUser = await $ajax.put(`${ENDPOINT}/${userData.userId}`, {}, userData);
				_populateUserTestProps(updateUser.data);
				commit('updateCurrentUsers', updateUser.data);
				return updateUser.data;
			},

			async getProfilePic(){
				let getPic =  await $ajax.get(`/profilePic`, {});
				console.log(getPic);
			},

			async uploadProfilePic({ commit }, profilePic){
                let formData = new FormData();
                formData.append(
                    'files', profilePic //No I18N
                );
                let newFile = await $ajax.post(`/profilePic`, {}, formData, {headers : {}});
				_populateUserTestProps(newFile.data);
				commit('updateCurrentUsers', newFile.data);
				return newFile.data;
            },

			async deleteUserProfilePic({ commit }){
				let user = await $ajax.delete(`/profilePic`, {});
				_populateUserTestProps(user.data);
				commit('updateCurrentUsers', user.data);
				return user.data;
			},
			async updatecurrentUserPassword(context, userData){
				await $ajax.get(`${ENDPOINT}/change_password?currPassword=${userData.currentPassword}&newPassword=${userData.confirmPassword}`, {});
			},

			async getUserPreference({ commit, state }, userId) {
				if(Object.entries(state.preferenceForUser).length){
					return state.preferenceForUser;
				}
				let userPre = await $ajax.get(`${USERENDPOINT}/${userId}/${USERPREENDPOINT}`);
				_populateUserpreTestProps(userPre.data);
				commit('setFetchedPreference', userPre.data);
				return userPre.data;
			},

			async updateUserPreference({ commit }, userData) {
				let userPre = await $ajax.put(`${USERENDPOINT}/${userData.userId}/${USERPREENDPOINT}`, {}, userData.preference);
				_populateUserpreTestProps(userPre.data);
				commit('setFetchedPreference', userPre.data);
				return userPre.data;
			},

			clearCache({commit}){
				commit('resetState');
			}
		}
	};
})();