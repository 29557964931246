import $ajax from './utils/ajax-adapter';

export default (function () {

	const ENDPOINT = '/taskpriorities';

	return {
		namespaced: true,
		state: {
			priorities: []
		},
		mutations: {
			setFetchedPriorities(state, priorities) {
				state.priorities = priorities;
			},
			resetState(state) {
				state.priorities = [];
			}
		},
		getters: {
			
		},
		actions: {
			async getAll({ commit, state }) {
				if (state.priorities.length) {
					return state.priorities;
				}
				let priorities = await $ajax.get(ENDPOINT);
				commit('setFetchedPriorities', priorities.data);
				return priorities.data;
			},
			clearCache({ commit }) {
				commit('resetState');
			}
		}
	};
})();