<template>
    <av-global-svg-sprites></av-global-svg-sprites>
    <router-view/>
</template>


<script>
import GlobalSvgSprites from '@/components/av-global-svg-sprites.vue';

export default {
	components: {
		'av-global-svg-sprites' : GlobalSvgSprites
	}
};
</script>

<style lang="css">
@import './assets/styles/fonts.css';
@import './assets/styles/main.css';
@import './assets/styles/theme-colors-default.css';

body >#av-app{
  height: 100vh;
  width: 100vw;
  background-color: #F2F2F2;
}
</style>
