import $ajax from './utils/ajax-adapter';

export default (function () {

	const ENDPOINT_TASKS = '/tasks';
	// const ENDPOINT_SUBTASKS = '/subtasks';
	const ENDPOINT_COMMENTS = 'comments';
	const ENDPOINT_TASKTAG = '/tasktags';


	const _populateTaskTestProps = function (task) {
		task.taskId = `${task.taskId}`;
		task.status = task.status || {};
		task.taskTags = task.taskTags || [];
		task.taskTags.forEach(tag => (tag.color = '#9013fe'));
		task.description = task.description === null ? task.description  : decodeURI(task.description);
		return task;
	};

	return {
		namespaced: true,
		state: {
			tags: [],
			tasks: [],
			taskAttachments : [],
			checklists: [],
			subTasks : [],
			comments : [],
			subTaskcomment : [],
			subTaskAttachments : [],
			fields: [
				{ key: 'taskTitle', text: 'Task Name', uiType: 1, isDisplayKeyField: true, cssClass: 'title-col', sortable: 'custom'},
				{ key: 'owners', text: 'Owners', uiType: 2, align: 'center', width: 120},
				{ key: 'status', text: 'Status', uiType: 5 },
				{ key: 'startDate', text: 'Start Date', uiType: 6, width: 120},
				{ key: 'dueDate', text: 'Due On', uiType: 4, sortable: 'custom', width: 120 },
				{ key: 'taskTags', text: 'Tags', uiType: 3, width: 200 },
				{ key: 'createdTime', text: 'Created On', uiType: 7, width: 120 },
				{ key: 'taskId', text: 'Task #', uiType: 8, width: 120}
			]
		},
		getters: {
			displayFields(state, getters, rootState) {
				let userPrerForTask = rootState.users.preferenceForUser;
				return state.fields.filter((field) => { return userPrerForTask.userPreferences.taskColumns.find(taskCol => taskCol === field.key);});
			},

			statusFieldObj(state) {
				return state.fields.find(field => field.uiType === 5);
			},

			taskForBoardView(state, getters, rootState){
				let tasksForStatusMap = [];
				let taskStatus = rootState.taskstatuses.statuses;

				taskStatus.forEach((status) => {
					tasksForStatusMap[status.taskStatusId] = state.tasks.filter(t => t.status.taskStatusId === status.taskStatusId);
				});
				return tasksForStatusMap;
			}
		},
		mutations: {
			// Task
			setFetchedTasks(state, tasks) {
				state.tasks = tasks;
			},
			pushNewTask(state, task) {
				state.tasks.push(task);
			},
			updateFetchedTask(state, task) {
				Object.assign(state.tasks.find(t => t.taskId === task.taskId), task);
			},
			// Attachments
			setFetchedTaskAttachment(state, taskAtt){
				state.taskAttachments = taskAtt;
			},
			pushNewTaskAttachment(state, taskAtt) {
				state.taskAttachments.push(taskAtt);
			},
			// Task Checklists
			fetchedChecklists(state, checklist){
				state.checklists = checklist;
			},
			pushNewChecklist(state, checklist) {
				state.checklists.push(checklist);
			},
			updateChecklist(state, checklist) {
				Object.assign(state.checklists.find(e => e.checkListId === checklist.checkListId), checklist);
			},
			// Task Subtask
			fetchedSubtasks(state, subTask){
				state.subTasks = subTask;
			},
			pushNewSubtasks(state, subTask) {
				state.subTasks.push(subTask);
			},
			updateSubtask(state, editedTask){
				Object.assign(state.subTasks.find(subtask => subtask.taskId === editedTask.taskId), editedTask);
			},
			// Task comments
			fetchedComments(state, comment){
				state.comments = comment;
			},
			pushNewComment(state, comment) {
				state.comments.push(comment);
			},
			updateComment(state, comment) {
				Object.assign(state.comments.find(c => c.taskCommentId === comment.taskCommentId), comment);
			},

			fetchsubTaskComments(state, subTaskcomment){
				state.subTaskcomment = subTaskcomment;
			},
			pushsubTaskcomments(state, subTaskcomments){
				state.subTaskcomment.push(subTaskcomments);
			},
			updateSubtaskcomments(state, editedComment){
				Object.assign(state.subTaskcomment.taskId === editedComment.taskId);
			},
			// sub task Attachments
			FetchedSubTaskAttachment(state, subtaskAtt){
				state.subTaskAttachments = subtaskAtt;
			},
			pushNewSubTaskAttachment(state, subtaskAtt) {
				state.subTaskAttachments.push(subtaskAtt);
			}
		},
		actions: {
			async getAll({ commit }, inputArgs) {
				let qp = Object.assign({ size: 100 }, (inputArgs && inputArgs.queryParams) || {});
				let tasks = await $ajax.get(ENDPOINT_TASKS, qp);
				let respArr = tasks.data.content;
				respArr.forEach((task) => {
					_populateTaskTestProps(task);
				});
				commit('setFetchedTasks', respArr);
				return respArr;
			},

			async getTask({ commit }, inputArg) {
				let taskResp = await $ajax.get(`${ENDPOINT_TASKS}/${inputArg.taskId}`);
				let taskObj = taskResp.data;
				_populateTaskTestProps(taskObj);
				commit('updateFetchedTask', taskObj);
				return taskObj;
			},

			async create({ commit }, task) {
				let createdTaskResp = await $ajax.post(ENDPOINT_TASKS, {}, task);
				await _populateTaskTestProps(createdTaskResp.data);
				commit('pushNewTask', createdTaskResp.data);
				return createdTaskResp;
			},

			//Newly added function to push task changes
			async updateTaskDetails({ commit }, taskChanges) {

				let updateTask = {...taskChanges};
				if(taskChanges.description === null ){
					updateTask.description = null;
				}
				else{
					updateTask.description = encodeURI(updateTask.description);
				}

				let taskResp = await $ajax.put(`${ENDPOINT_TASKS}/${taskChanges.taskId}`, {}, updateTask, {});
				let taskObj = taskResp.data;
				await _populateTaskTestProps(taskObj);
				commit('updateFetchedTask', taskObj);
				return taskObj;
			},

			// Attachments
			async getTaskAttachment({ commit }, taskId) {
				let taskAttachment = await $ajax.get(`${ENDPOINT_TASKS}/${taskId}/attachments`);
				commit('setFetchedTaskAttachment', taskAttachment.data);
				return taskAttachment;
			},

			async uploadFile({commit}, taskAttach){
                let formData = new FormData();
                formData.append(
                    'files', taskAttach.file //No I18N
                );
                let newFile = await $ajax.post(`/tasks/${taskAttach.taskId}/attachments`, {}, formData, {headers : {}});
				newFile.data.map(file => commit('pushNewTaskAttachment', file));
				return newFile.data;
            },

			async deleteAttachments(context, taskAttach){
				await $ajax.delete(`${ENDPOINT_TASKS}/${taskAttach.taskId}/attachments/${taskAttach.attachmentId}`);
			},

			async downloadAttachments(context, attachmentId){
				await $ajax.get(`/attachments/${attachmentId}`);
			},

			async bulkUpdate({ commit }, bulkUpdateArr){
				const patch_options = {
                    headers: { 'Content-Type': 'application/json-patch+json' }
                };
				let taskResp = await $ajax.patch(`${ENDPOINT_TASKS}/bulk_update`, {}, bulkUpdateArr, patch_options);
				let taskObj = taskResp.data;
				
				for(let i = 0; i < taskObj.length; i++){
					await _populateTaskTestProps(taskObj[i]);
					commit('updateFetchedTask', taskObj[i]);
				}
				return taskObj;
			
			},

			async bulkDeleteTasks(context, deleteTaskValue){
				await $ajax.delete(`${ENDPOINT_TASKS}/bulk_delete`, {}, deleteTaskValue);
			},

			async taskToSubtask(context, subTotask){
				console.log(subTotask);
				for( let i = 0; i < subTotask.convertTaskId.length; i++){
					await $ajax.get(`${ENDPOINT_TASKS}/${subTotask.convertTaskId[i]}/convert?taskListId=1&parentId=${subTotask.parentTaskId}`, {});
				}
			},

			async getTaskTags(){
				let tags = await $ajax.get(ENDPOINT_TASKTAG);
				return tags;
			},

			async deleteTaskTag(context, taskTag){
				// console.log(`${ENDPOINT_TASKTAG}/${taskTag.taskTagId}`);
				await $ajax.delete(`${ENDPOINT_TASKTAG}/${taskTag.taskTagId}`, {});
			},

			//CHECKLIST FUNCTIONS

			//Newly added function to get a checklist item

			async getChecklists({ commit }, taskId) {
				let output = await $ajax.get(`${ENDPOINT_TASKS}/${taskId}/checklists`);
				let checklistObj = output.data;
				commit('fetchedChecklists', checklistObj);
				return checklistObj;
				
			},

			//Newly added function to add Check list
			async createChecklist({ commit }, checkListObj) {
				checkListObj.completed = false;
				let checklist = await $ajax.post(`${ENDPOINT_TASKS}/${checkListObj.parentTaskId}/checklists`, {}, checkListObj, {});
				commit('pushNewChecklist', checklist.data);
				return checklist;
			
			},

			//Newly added function to update Checklist
			async updateChecklist({ commit }, checkListObj) {
				let checklist = await $ajax.put(`${ENDPOINT_TASKS}/${checkListObj.parentTaskId}/checklists/${checkListObj.checkListId}`, {}, checkListObj, {});
				commit('updateChecklist', checklist.data);
			},

			//Function to delete a checklist item
			async deleteChecklist(context, checkListObj) {
				await $ajax.delete(`${ENDPOINT_TASKS}/${checkListObj.parentTaskId}/checklists/${checkListObj.checkListId}`, {});

			},

			// get sub Task

			async getsubTask({ commit }, taskId) {
				let subTask = await $ajax.get(`${ENDPOINT_TASKS}/${taskId}/subtasks`);
				let subTaskobj = await subTask.data.map(t => _populateTaskTestProps(t));
				commit('fetchedSubtasks', subTaskobj);
				return subTaskobj;
				
			},


			//Newly added function to add Subtask
			async createsubTask({ commit }, subtaskObj) {
				let output = await $ajax.post(`${ENDPOINT_TASKS}/${subtaskObj.parentId}/subtasks`, {}, subtaskObj, {});
				let respArr = output.data;
				_populateTaskTestProps(respArr);
				commit('pushNewSubtasks', respArr);
				return respArr;

			},

			// delete Subtask

			async deleteSubtask(context, subtaskObj){
				await $ajax.delete(`${ENDPOINT_TASKS}/${subtaskObj.parentTaskId}/subtasks/${subtaskObj.taskId}`, {});

			},

			// Get All comments
			async getComments({ commit }, taskId) {
				let output = await $ajax.get(`${ENDPOINT_TASKS}/${taskId}/${ENDPOINT_COMMENTS}`);
				let commentObj = output.data;
				commit('fetchedComments', commentObj);
			},

			async getAllComments({ commit }, inputArg) {
				let output = await $ajax.get(`${ENDPOINT_TASKS}/${inputArg.taskId}/${ENDPOINT_COMMENTS}`);
				let commentObj = output.data;
				commit('fetchedComments', commentObj);
			},

			//Newly added function to add Comments
			async createComment({ commit }, commentObj) {
				let taskId = commentObj.parentTaskId;
				let comment = await $ajax.post(`${ENDPOINT_TASKS}/${taskId}/${ENDPOINT_COMMENTS}`, {}, commentObj.comment, {});
				let updateComments = comment.data;
				commit('pushNewComment', updateComments);
			},

			// delete comments
			async deleteComment(context, commentObj) {
				let taskId = commentObj.parentTaskId;
				let taskCommentId = commentObj.taskCommentId;
				await $ajax.delete(`${ENDPOINT_TASKS}/${taskId}/${ENDPOINT_COMMENTS}/${taskCommentId}`);
			},

			// update comments
			async updateComment({ commit }, commentObj) {
				let taskId = commentObj.parentTaskId;
				let taskCommentId = commentObj.taskCommentId;
				let output = await $ajax.put(`${ENDPOINT_TASKS}/${taskId}/${ENDPOINT_COMMENTS}/${taskCommentId}`, {}, commentObj, {});
				commit('updateComment', output.data );
				return output;
			},

			// Selected Subtask

			async selectedsubTask({ commit }, SubTaskArr){
				let subtaskResp = await $ajax.get(`${ENDPOINT_TASKS}/${SubTaskArr.parentTaskId}/subtasks/${SubTaskArr.taskId}`);
				let subtaskObj = subtaskResp.data;
				_populateTaskTestProps(subtaskObj);
				commit('updateSubtask', subtaskObj);
				return subtaskObj;

			},

			async updateSelectedsubTask({ commit }, subtask){
				let taskId = subtask.parentTaskId;
				delete subtask.parentTaskId;
				let taskResp = await $ajax.put(`${ENDPOINT_TASKS}/${taskId}/subtasks/${subtask.taskId}`, {}, subtask, {});
				let output = taskResp.data;
				_populateTaskTestProps(output);
				commit('updateSubtask', output);
				return output;
			},

			async getsubTaskcomments({ commit }, commentsObj) {
				let output = await $ajax.get(`${ENDPOINT_TASKS}/${commentsObj.taskId}/subtasks/${commentsObj.subtaskId}/${ENDPOINT_COMMENTS}`, {});
				let commentObjdata = output.data;
				commit('fetchsubTaskComments', commentObjdata);
			},

			async subTaskcreateComment({ commit }, commentsObj){
				let output = await $ajax.post(`${ENDPOINT_TASKS}/${commentsObj.parentTaskId}/subtasks/${commentsObj.subtaskId}/${ENDPOINT_COMMENTS}`, {}, commentsObj.comment, {});
				let commentObjdata = output.data;
				commit('pushsubTaskcomments', commentObjdata);
			},

			async subTaskupdateComment({ commit }, commentsObj) {
				let parentId = commentsObj.parentId;
				delete commentsObj.parentId;
				await $ajax.put(`${ENDPOINT_TASKS}/${parentId}/subtasks/${commentsObj.taskId}/${ENDPOINT_COMMENTS}/${commentsObj.taskCommentId}`, {}, commentsObj, {});
				commit('updateSubtaskcomments', commentsObj);
			},

			async subTaskdeleteComment(context, commentsObj) {
				let taskId = commentsObj.taskId;
				let subtaskId = commentsObj.subtaskId;
				let taskCommentId = commentsObj.taskCommentId;
				await $ajax.delete(`${ENDPOINT_TASKS}/${taskId}/subtasks/${subtaskId}/${ENDPOINT_COMMENTS}/${taskCommentId}`);
				
			},

			async getSubTaskAttachment({ commit }, taskObj) {
				let taskAttachment = await $ajax.get(`${ENDPOINT_TASKS}/${taskObj.parentTaskId}/subtasks/${taskObj.taskId}/attachments`);
				commit('FetchedSubTaskAttachment', taskAttachment.data);
				return taskAttachment;
			},


			async subTaskAttachmentUpload({commit}, taskAttach){
                let formData = new FormData();
                formData.append(
                    'files', taskAttach.file //No I18N
                );
                let newFile = await $ajax.post(`${ENDPOINT_TASKS}/${taskAttach.parentId}/subtasks/${taskAttach.taskId}/attachments`, {}, formData, {headers : {}});
				// console.log(newFile);
				newFile.data.map(file => commit('pushNewSubTaskAttachment', file));
				return newFile.data;
            },


			async deletesubTaskAttachments(context, taskAttach){
				// console.log(taskAttach);
				await $ajax.delete(`${ENDPOINT_TASKS}/${taskAttach.parentTaskId}/subtasks/${taskAttach.taskId}/attachments/${taskAttach.attachmentId}`);
			},

			async downloadsubTaskAttachments(context, attachmentId){
				await $ajax.get(`/attachments/${attachmentId}`);
			}
		}
	};
})();
