import { createRouter, createWebHistory } from 'vue-router';
// import store from '@/store';

const routes = [
	{
		path : '/',
		redirect: { name: 'home'},
		meta: {
            auth : true
        },
		// redirect: { name: 'home'},
		component: () =>
			import(/* webpackChunkName: "main-layout" */ '../views/av-main-layout.vue'),
			// async beforeEnter(){
			// 	let currentUser;
			// 	await store.dispatch("users/getCurrentUser").then((response) => {
			// 		currentUser = response;
			// 	});
			// 	await store.dispatch('users/getUserPreference', currentUser.userId);
			// 	store.dispatch('organizations/getCurrentOrg');

			// },
		children : [
			{
				path: '/search',
				name: 'search',
				component: () =>
					//import(/* webpackChunkName: "route-home" */ '../views/av-home.vue')
					import(/* webpackChunkName: "route-home" */ '../views/av-searchpage.vue')
			},
			{
				path: '/landingPage',
				name: 'landingPage',
				component: () =>
					import(/* webpackChunkName: "main-layout" */ '../components/av-landingPage.vue')
			},
			{
				path: '/home',
				name: 'home',
				component: () =>
					//import(/* webpackChunkName: "route-home" */ '../views/av-home.vue')
					import(/* webpackChunkName: "route-home" */ '../views/av-homepage.vue')
				// beforeEnter(to, from, next){
				// 	console.log(to, from);
				// 	next();
				// }
			},
			{
				path: '/tasks',
				name: 'tasks',
				// component: Home
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () =>
					import(/* webpackChunkName: "route-home" */ '../views/av-tasks.vue'),
				children : [
					{
						path: ':taskId',
						name: 'task-details',
						// component: Home
						// route level code-splitting
						// this generates a separate chunk (about.[hash].js) for this route
						// which is lazy-loaded when the route is visited.
						component: () =>
							import(/* webpackChunkName: "route-home" */ '../views/av-tasks-details-view.vue')
				
					},
					{
						path: ':taskId/subtasks/:subtaskId',
						name: 'subtask-details',
						// component: Home
						// route level code-splitting
						// this generates a separate chunk (about.[hash].js) for this route
						// which is lazy-loaded when the route is visited.
						component: () =>
							import(/* webpackChunkName: "route-home" */ '../views/av-subtasks.vue')
					}
				]
			}
		]
	},
	
	{
		path:'/uiexp',
		name:'Uiexperiment',
		component: () =>
			import(/* webpackChunkName: "main-layout" */ '../views/ui_experiments.vue')
	}

	// {
	// 	path:'/demotest',
	// 	name:'demoTest',
	// 	component: () =>
	// 		import(/* webpackChunkName: "main-layout" */ '../views/demo-test.vue')
	// },
	// {
	// 	path:'/user',
	// 	name:'user-pre',
	// 	component: () =>
	// 		import(/* webpackChunkName: "main-layout" */ '../views/demo-test.vue')
	// }

];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});


export default router;
