export default (function($http){

    const OPTIONS = {
        headers: { 'Content-Type': 'application/json'}
    };

    let serializeQueryParams = function (obj) {
        let str = [];
        for (let p in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, p)) {
                str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
            }
        }
        return str.join('&');
    };

    let ajax = async function (url1, method, queryParams, options, bodyObj){
		//console.log(url1);
		let url = 'https://app.astravue.com' + url1;
		
		//console.log(url);

        let newOptions = Object.assign({}, OPTIONS);
        newOptions = Object.assign(newOptions, options);
        newOptions.method = method;
        if(bodyObj instanceof FormData || bodyObj instanceof File){
            newOptions.body = bodyObj;
        }else{
            let bodyObjStr = bodyObj && Object.keys(bodyObj).length && JSON.stringify(bodyObj);
            if (bodyObjStr && bodyObjStr.trim()){
                newOptions.body = bodyObjStr;
            }
        }
        
        // let response = await fetch(`${url}${queryParams && Object.keys(queryParams).length ? `?${serializeQueryParams(queryParams)}` : ''}`, newOptions);
		let response = await fetch(`${url}${queryParams && Object.keys(queryParams).length ? `?${serializeQueryParams(queryParams)}` : ''}`, newOptions,
		{credentials: 'same-origin'});
        if (!response.ok) throw new Error(response.status);
        return response.json();
    };

    $http.get = async function(url, queryParams, options){
        let response = await ajax(url, 'GET', queryParams, options);
        return response;
    };

    $http.delete = async function (url, queryParams, bodyObj, options) {
        let response = await ajax(url, 'DELETE', queryParams, options, bodyObj);
        return response;
    };

    $http.post = async function (url, queryParams, bodyObj, options ) {
        let response = await ajax(url, 'POST', queryParams, options, bodyObj);
        return response;
    };

    $http.put = async function (url, queryParams, bodyObj, options) {
        let response = await ajax(url, 'PUT', queryParams, options, bodyObj);
        return response;
    };
    $http.patch = async function (url, queryParams, bodyObj, options) {
        let response = await ajax(url, 'PATCH', queryParams, options, bodyObj);
        return response;
    };


    return $http;

})({});